import { useMutation } from '@apollo/client'
import FormButton from 'components/FormButton'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Input from 'components/forms/Input'
import Label from 'components/forms/Label'
import { navigate } from 'gatsby'
import { SIGN_UP } from 'lib/graphql/auth'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/auth/slice'

export default function SignUpForm() {
  const dispatch = useDispatch()
  const [signUpPayload, setSignUpPayload] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
  })
  const [errors, setErrors] = useState([])
  const { email, password, firstName, lastName, phone } = signUpPayload

  const [signUp, { loading, error, data }] = useMutation(SIGN_UP, {
    onCompleted: data => {
      if (data.customerCreate.customerUserErrors.length > 0) {
        return setErrors(data.customerCreate.customerUserErrors)
      } else if (data.customerCreate.customer) {
        dispatch(authActions.setUser(data.customerCreate.customer))
        dispatch(authActions.setLoggedInUser())
        navigate('/my-account')
      }
    },
    onError: error => {
      console.log('error:', error)
    },
  })

  const handleChange = e => {
    setSignUpPayload({
      ...signUpPayload,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors([])
    signUp({
      variables: {
        input: signUpPayload,
      },
    })
  }

  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '36rem',
        width: '100%',
        minHeight: '80vh',
        padding: '5rem 0',
      }}
    >
      <form
        onSubmit={handleSubmit}
        css={{
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '0.5rem',
          height: 'auto',
          padding: '3rem',
        }}
      >
        <h3 css={{ fontSize: '3rem', textAlign: 'center' }}>Create account</h3>
        <div css={{ margin: '2rem 0' }}>
          <Label for='firstName'>First Name</Label>
          <Input
            value={firstName}
            onChange={handleChange}
            name='firstName'
            placeholder='First Name'
          />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <Label for='lastName'>Last Name</Label>
          <Input value={lastName} onChange={handleChange} name='lastName' placeholder='Last Name' />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <Label for='phone'>Your phone</Label>
          <Input value={phone} onChange={handleChange} name='phone' placeholder='Your phone' />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <Label for='email'>Email</Label>
          <Input
            value={email}
            onChange={handleChange}
            type='email'
            name='email'
            placeholder='Enter your email'
          />
        </div>
        <div css={{ margin: '2rem 0' }}>
          <Label for='password'>Password</Label>
          <Input
            value={password}
            onChange={handleChange}
            type='password'
            name='password'
            placeholder='Enter your password'
          />
        </div>
        <FormButton
          disabled={loading}
          handleClick={handleSubmit}
          icon={<Icon.ArrowForward width={16} height={16} />}
        >
          Create
        </FormButton>
        <div css={{ textAlign: 'center', marginTop: '3rem' }}>
          <Link to='/login' css={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Already have an account?
          </Link>
        </div>
        {errors?.map((e, i) => (
          <p key={i} css={{ color: 'red', textAlign: 'center' }}>
            {e.message}
          </p>
        ))}
      </form>
    </div>
  )
}
