import Container from 'components/Container'
import SignUpForm from 'components/auth/SignUpForm'
import withIsLoggedIn from 'components/hocs/withIsLoggedIn'
import React from 'react'
import { Helmet } from 'react-helmet'

function SignUpPage() {
  return (
    <Container css={{ position: 'relative' }}>
      <Helmet title='Creat a new account'>
        <meta name='robots' content='noindex' />
      </Helmet>
      <SignUpForm />
    </Container>
  )
}
export default withIsLoggedIn(SignUpPage)
